import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Header from '../header-footer/Header';

const CountriesAndAreas = () => {
  document.title = 'Gravity Report';
  const navigate = useNavigate();
  const [data, setdata] = useState({});
  const param = useParams();


  useEffect(() => {
    // Fetch data only if param.region has a value
    if (param.id) {
      const countriesFun = async () => {
        try {
          const data = await fetch(
            `${process.env.REACT_APP_API_URL}/location/countries_area_all?title=${param.id}`
          );
          const json = await data.json();
          setdata(json?.data[0]?.countries[0]);
          // console.log(json);
        } catch (error) {
          console.log(error);
        }
      };
      countriesFun();
    }
  }, [param.id]); // Add param.region as a dependency

  // Memoize location.state
  // const memoizedLocationState = useMemo(() => location?.state ?? {}, [location?.state]);

  // Function to handle click on list item
  const handleListItemClick = (preroute, grEndpoint) => {
    navigate(`/${preroute}/${grEndpoint}`);
  };

  return (
    <div className='mx-auto w-[25.5rem] h-full font-sans'>
      <div className=' h-[4rem] '>
        <Header />

      </div>

      <h1 className='text-[24px] font-semibold ml-2 '>{data.title}</h1>
      <p className='text-[16px] ml-2 leading-4 mb-10'>
        Find weather conditions for the best climbing areas in {data.title}.
      </p>

      {/* Render top areas */}
      <h3 className='font-semibold text-[20px] leading-6 ml-2 my-4'>Top Areas</h3>
      <ul>
        {data?.topAreas?.map((data) => (
          <li key={data.title} className='mb-1 ml-2 cursor-pointer' onClick={() => handleListItemClick(param.id, data?.grEndpoint)}>
            <p className='text-[#0B70E7] text-[16px] font-medium underline leading-5 inline'>{data.title}</p>
            <p className='text-black ml-1 text-[16px] font-medium leading-5 inline'>{data.region}</p>
          </li>
        ))}
      </ul>

      {/* Render all areas */}
      <h3 className='font-semibold text-[20px] leading-6 ml-2 my-4'>All Areas</h3>
      <ul>
        {data?.areas?.map((data) => (
          <li key={data.title} className='mb-1 ml-2 cursor-pointer' onClick={() => handleListItemClick(param.id, data?.grEndpoint)}>
            <p className='text-[#0B70E7] text-[16px] font-medium underline leading-5 inline '>{data.title}</p>
            <p className='text-black text-[16px] font-medium leading-5 ml-1 inline '>{data.region}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CountriesAndAreas;
