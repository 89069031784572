import React from "react";

const Footer = () => {
  return (
    <footer className="flex justify-center pb-7 right-0 left-0">
      <p className="font-normal text-center sm:text-sm md:text-base">
        Find the best conditions with the <a target="_blank" className="text-underline" rel="noreferrer" href="https://gravityreport.app.link/civWh0qbUHb">Gravity Report</a> climbing app.{" "}
      </p>

    </footer>
  );
};

export default Footer;