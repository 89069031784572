import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ContinentsAndCountries from "./components/ContinentsAndCountries";
import CountriesAndAreas from "./components/CountriesAndAreas";
import Maps from "./components/Maps";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<ContinentsAndCountries />} />
        <Route path="/:id" element={<CountriesAndAreas />} />
        <Route path="/:id/:region" element={<Maps />} />
      </Routes>
    </Router>
  );
};

export default App;
