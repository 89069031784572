import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const CountriesComponent = ({ gre }) => {
  const navigate = useNavigate();
  const [countries, setCountries] = useState([]); // Use empty array for default state

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const data = await fetch(
          `${process.env.REACT_APP_API_URL}/location/countries?grEndpoint=${gre}`
        );
        const json = await data.json();
        setCountries(json?.data[0]?.countries || []); // Set to empty array if undefined
      } catch (error) {
        console.error("Error fetching countries:", error);
        setCountries([]); // Set countries to empty array to indicate error
      }
    };

    fetchCountries();
  }, [gre]);

  return (
    <ul className="mt-3 mb-3">
      {countries?.map((data) => (
        <li
          key={data?.latitude} // Use a unique key
          className="text-[#0B70E7] text-[16px] font-medium underline leading-5 mb-1"
          onClick={() => {
            navigate("/" + data?.grEndpoint, {
              state: {
                areas: data?.areas,
                topareas: data?.topAreas,
                title: data?.title,
                preroute: data?.grEndpoint,
              },
            });
          }}
        >
          {data?.title}
        </li>
      ))}
    </ul>
  );
};

export default CountriesComponent;