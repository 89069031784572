import React, { useEffect, useState } from "react";
import CountriesComponent from "./CountriesComponent";
import Header from "../header-footer/Header";

const ContinentsAndCountries = () => {
  document.title = 'Gravity Report';
  const [continents, setContinents] = useState([]);

  // Optimized fetch function using async/await and a try/catch block
  useEffect(() => {
    const fetchContinents = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/location/area`);
        const data = await response.json();
        setContinents(data.data);
      } catch (error) {
        console.error("Error fetching continents:", error);
      }
    };

    // Call the fetch function
    fetchContinents();
  }, []);

  return (
    <div className="mx-auto w-[25.5rem] h-full font-sans">

    <div className="h-[4rem] ">

      <Header />
    </div>

      <h1 className="text-[24px] font-semibold  ml-2 font-sans">Gravity Report</h1>
      <p className="text-[16px] ml-2 leading-4 mb-10 mt-2">
        Find weather conditions for the best climbing areas in the world.
      </p>
      <ul>
        {/* Use a key prop for the li element */}
        {continents?.map(continent => (
          <li key={continent.id} className="font-semibold text-[20px] leading-6 block ml-2 font-sans cursor-pointer">
            {continent.title}
            <CountriesComponent gre={continent.grEndpoint} />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ContinentsAndCountries;