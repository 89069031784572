import React, { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import Header from "../header-footer/Header";
import Footer from "../header-footer/Footer";
import AppleMapsButton from "../assets/AppleMapsButton.svg";
import GoogleMapsButton from "../assets/GoogleMapsButton.svg";
import GravityReportButtonSvg from "../assets/GravityReportButton.svg";
import { Map, Marker } from "react-mapkit";

const Maps = () => {

  const tokenID =
    "eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IjhIVkszNDlGN0sifQ.eyJpc3MiOiJSUzc5ODlOTTM5IiwiaWF0IjoxNzA5OTczMTM0LCJleHAiOjE3NDEzMDU2MDAsIm9yaWdpbiI6Imh0dHBzOi8vYXJlYXMuZ3Jhdml0eXJlcG9ydC5hcHAifQ.7t87qxIVRhxA3Yw-7wNEjnbTy0ADbpjuo5_pNGmrVH-VWu0sJL6ja42Y09L33XkzFu-v6afHw6ylG7CmrkyUDQ";
  const param = useParams();
  const [data, setdata] = useState({});

  // Memoize data
  const memoizedData = useMemo(() => data ?? {}, [data]);

  // console.log(param);

  useEffect(() => {
    // console.log('memoizedData', memoizedData);
    if (memoizedData) {
      let title = memoizedData?.climbingType + ' area in ' + (memoizedData?.region === "" ? "" : ", ") + memoizedData?.country;
      title = title.charAt(0).toUpperCase() + title.slice(1);
      document.title = 'Gravity Report – ' + title;
    }
  }, [memoizedData]);

  useEffect(() => {
    // Fetch data only if param.region has a value
    if (param.region) {
      const countriesFun = async () => {
        try {
          const data = await fetch(
            `${process.env.REACT_APP_API_URL}/location/countries_area?title=${param.region}`
          );
          const json = await data.json();
          setdata(json?.data);
          // console.log(json);
        } catch (error) {
          console.log(error);
        }
      };
      countriesFun();
    }
  }, [param.region]); // Add param.region as a dependency

  // Function to open Apple Maps
  const openAppleMaps = () => {
    // console.log(data);
    window.open(
      `https://maps.apple.com/?q=${memoizedData?.latitude},${memoizedData?.longitude}`,
      "_blank"
    );
  };

  // Function to open Google Maps
  const openGoogleMaps = () => {
    // console.log(data);
    window.open(
      `https://www.google.com/maps?q=${memoizedData?.latitude},${memoizedData?.longitude}`,
      "_blank"
    );
  };

  const gravityReportButton = async () => {
    // alert('Gravity report button clicked');
    try {
      let params = `title=${memoizedData?.title}&latitude=${memoizedData?.latitude}&longitude=${memoizedData?.longitude}`;
      const response = await fetch(`${process.env.REACT_APP_API_URL}/get_branch_url?${params}`);
      const data = await response.json();
      console.log(data);
      if (data.statusCode === 200) {
        let link = data.data.url || 'https://gravityreport.app';
        // alert(`Link: ${link}`);
        
        if ((navigator.vendor.match(/apple/i) || "").length > 0) {
          console.log('IN SAFARI BROWSER >>>>>')
          document.getElementById('deepLinkRedirectionDiv').style.display = "block";
          var a = document.getElementById('deepLinkRedirection');
          a.href = link;
          // a.innerHTML = link;
          setTimeout(() => {
            console.log('In Timeout');
            document.getElementById('deepLinkRedirection').click();
          }, 200);
        } else {
          window.open(link, "_blank");
        }
      }
    } catch (error) {
      console.error("Error fetching Gravity report link:", error);
    }
  }

  return (
    <div className="flex flex-col h-screen">
      <Header />

      <div className="flex-1 flex items-center justify-center pb-7">
        <div className="w-[430px]">
          <div className="flex flex-col items-start w-full p-2">
            <h2 className="text-2xl font-semibold w-full max-w-sm">
              {memoizedData?.title}
            </h2>
            <p className="text-base font-normal w-full text-wrap pb-3p">
              {memoizedData?.title} is a {memoizedData?.climbingType} area in{" "}
              {memoizedData?.region}
              {memoizedData?.region === "" ? "" : ", "}
              {memoizedData?.country}.
            </p>

            <p className="text-gray-400 text-sm font-normal rounded-md w-full">
              Get directions to the area or view climbing conditions and
              climbing weather forecasts in the Gravity Report app.
            </p>
          </div>
          <div className="flex items-center justify-center map-h">
            {memoizedData?.latitude && memoizedData?.longitude ? (
              <Map
                tokenOrCallback={tokenID}
                // center={[memoizedData.latitude, memoizedData.longitude]}
                region={{
                  latitude: memoizedData.latitude,
                  longitude: memoizedData.longitude,
                  latitudeSpan: 0.1,
                  longitudeSpan: 0.1,
                }}
              >
                <Marker
                  latitude={memoizedData.latitude}
                  longitude={memoizedData.longitude}
                />
              </Map>
            ) : (
              <p>Loading map...</p>
            )}
          </div>
          <div className="p-2">
            <div className="flex justify-between">
              <button onClick={openAppleMaps}>
                <img
                  src={AppleMapsButton}
                  className="w-[430px]"
                  alt="AppleMapsButton"
                />
              </button>
              <button onClick={openGoogleMaps} className="pl-6">
                <img
                  src={GoogleMapsButton}
                  className="w-[430px]"
                  alt="GoogleMapsButton"
                />
              </button>
            </div>
            <div className="pt-6">
              <button className="gravity-report-button" onClick={gravityReportButton}>
                {/* <span style={{
                  color: '#48D6FF'
                }}>Open in</span>
                <span style={{
                  color: '#1CDA3A'
                }}> Gravity Report</span> */}
                <img
                  src={GravityReportButtonSvg}
                  className="w-[430px]"
                  alt="GoogleMapsButton"
                />
              </button>
            </div>
          </div>
          <div className="pb-3p" id="deepLinkRedirectionDiv">
            <h6 className="text-center" style={{ fontSize: '10px', cursor: 'pointer' }}>
              <a id="deepLinkRedirection" target="_blank" rel="noopener noreferrer" style={{ 
                color: "transparent",
                position: "absolute"
              }}>Not opened, Click here to open in Gravity Report</a>
            </h6>
          </div>
          <div>
            <p className="text-center text-md font-normal leading-4">
              View {memoizedData?.title} current climbing conditions and
              upcoming weather forecast in the <a target="_blank" className="text-underline" href="https://gravityreport.app/">Gravity Report</a> app.
            </p>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Maps;