import React from "react";
import hamburgerMenu from "../assets/hamburgerMenu.svg";
const Header = () => {

  const redirectHomePage = () => {
    window.open('https://gravityreport.app/', '_blank');
  }

  return (
    <header>
      <div style={{ height: '5vh' }}></div>
      <img onClick={() => redirectHomePage()}
        src={hamburgerMenu}
        alt=""
        className="absolute right-[1rem] top-[1.25rem] w-6"
      ></img>
    </header>
  );
};
export default Header;
